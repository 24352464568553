import React, { useContext, useEffect, useState } from "react";

import { ButtonGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useQuery } from "@tanstack/react-query";
import { Link, navigate } from "gatsby";

import Tabs from "~/components/Common/Tabs";
import LoadingError from "~/components/Loaders/LoadingError";
import ActionReceiverComponent from "~/components/Nurses/ActionReceiverComponent";
import DisplayBlockedItem from "~/components/Nurses/NurseBlocks/DisplayBlockedItem";
import NurseData from "~/components/Nurses/NurseData";
import { FiltersContext } from "~/components/Stores/FilterStore";
import DisplayBlockedTimeBlock from "~/components/Timeblocks/DisplayBlockedTimeBlock";
import NewDisplayTimeblock from "~/components/Timeblocks/NewDisplayTimeblock";
import { PossibleTab, TABS } from "~/constants/blocksNurseTabs";
import { activateNurse, fetchNurseProfile } from "~/utils/api/v1/nurseService";
import { nurseAPI } from "~/utils/api/v2";
import { getBlockedItems } from "~/utils/api/v2/nurse";
import sectorsAPI from "~/utils/api/v2/places";
import { getAllCountryTimeblocks, getNurseTimeblocks } from "~/utils/api/v2/timeblocks";
import { Locale } from "~/utils/interfaces/Locale";
import type { Nurse } from "~/utils/interfaces/Nurse";
import { GroupedBlockedTimeBlocks } from "~/utils/interfaces/Timeblock";
import { groupBlockedTimeBlocks } from "~/utils/timeblocks/group";

type NurseProfileProps = {
  nurseId: string;
};

function NurseProfile({ nurseId }: NurseProfileProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [nurseData, setNurseData] = useState<Nurse | undefined>();
  const [blockedTimeBlocks, setBlockedTimeBlocks] = useState<GroupedBlockedTimeBlocks>({});
  const [currentTab, setCurrentTab] = useState<"Por día" | "Por item">("Por día");
  // @ts-expect-error Type 'Filters' must have a '[Symbol.iterator]()' method that returns an iterator.ts(2488)
  const [, filtersDispatch] = useContext(FiltersContext);

  const {
    data: nurseDataQuery,
    isLoading: isNurseDataLoading,
    error: nurseDataError,
    refetch: refetchNurseData,
  } = useQuery({
    queryKey: ["nurseData", nurseId],
    queryFn: async () => {
      const response = await fetchNurseProfile(nurseId);
      return response.data;
    },
  });

  const {
    data: nurseTimeblocksData = { blocked: [], timeblocks: [] },
    isLoading: isNurseTimeblocksLoading,
    error: nurseTimeblocksError,
  } = useQuery({
    queryKey: ["nurseTimeblocks", nurseId],
    queryFn: async () => {
      const response = await getNurseTimeblocks(nurseId);
      return response.data;
    },
  });

  const {
    data: nurseLabs = [],
    isLoading: isNurseLabsLoading,
    error: nurseLabsError,
  } = useQuery({
    queryKey: ["nurseLabs", nurseId],
    queryFn: async () => await nurseAPI.fetchNurseLabs(nurseId),
  });

  const {
    data: allTimeblocksData = [],
    isLoading: isTimeblocksLoading,
    error: timeblocksError,
  } = useQuery({
    queryKey: ["allTimeblocks", nurseData?.country],
    queryFn: async () => getAllCountryTimeblocks(nurseData?.country as Locale),
    enabled: !!nurseData?.country,
  });

  const {
    data: sectorsData = [],
    isLoading: isSectorsLoading,
    error: sectorsError,
  } = useQuery({
    queryKey: ["sectors", nurseId],
    queryFn: async () => sectorsAPI.fetchNurseSectors(nurseId),
  });

  const {
    data: blockedItems = [],
    isLoading: isBlockedItemsLoading,
    error: blockedItemsError,
  } = useQuery({
    queryKey: ["blockedItems", nurseId],
    queryFn: async () => {
      const res = await getBlockedItems(nurseId);
      return res.data;
    },
  });

  const handleActivate = async () => {
    setLoading(true);
    try {
      const data = {
        active: !nurseData?.active,
      };
      await activateNurse(nurseId, data);
      refetchNurseData();
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const isLoading =
    loading ||
    isNurseDataLoading ||
    isNurseTimeblocksLoading ||
    isNurseLabsLoading ||
    isTimeblocksLoading ||
    isSectorsLoading ||
    isBlockedItemsLoading;

  useEffect(() => {
    if (nurseDataError) {
      setError(nurseDataError);
    } else if (nurseTimeblocksError) {
      setError(nurseTimeblocksError);
    } else if (timeblocksError) {
      setError(timeblocksError);
    } else if (nurseLabsError) {
      setError(nurseLabsError);
    } else if (sectorsError) {
      setError(sectorsError);
    } else if (blockedItemsError) {
      setError(blockedItemsError);
    }
  }, [nurseTimeblocksError, timeblocksError, nurseLabsError, sectorsError, blockedItemsError]);

  useEffect(() => {
    if (nurseTimeblocksData) {
      setBlockedTimeBlocks(groupBlockedTimeBlocks(nurseTimeblocksData.blocked) as GroupedBlockedTimeBlocks);
    }
  }, [nurseTimeblocksData.blocked.length]);

  useEffect(() => {
    if (nurseDataQuery) {
      setNurseData(nurseDataQuery);
    }
  }, [nurseDataQuery]);

  useEffect(() => {
    if (nurseData) {
      filtersDispatch({
        type: "UPDATE_NURSE",
        payload: nurseData.id,
      });
    }
  }, [nurseData]);

  return (
    <>
      <LoadingError
        loading={isLoading}
        error={error}
      />
      {!isLoading && nurseData && (
        <>
          <NurseData
            nurseData={nurseData}
            setNurseData={setNurseData}
            handleActivate={handleActivate}
            nurseLabs={nurseLabs}
            setError={setError}
          />
          <div className="p-12 my-4 rounded-lg bg-white border border-gray-200 flex flex-col">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center mx-4 mt-4">
                <p className="font-bold">Horarios habilitados</p>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={async () => navigate("/dashboard/")}
                  >
                    Ver agenda
                  </Button>
                  <Button
                    color="primary"
                    onClick={async () => navigate(`/health-team/${nurseId}/schedule/`)}
                  >
                    Editar
                  </Button>
                </ButtonGroup>
              </div>
              <hr />
              <NewDisplayTimeblock
                nurseId={nurseId}
                allTimeblocks={allTimeblocksData}
                activeTimeblocks={nurseTimeblocksData.timeblocks}
                countSectors={sectorsData.length}
                className="ml-8"
              />
            </div>
          </div>
          <div className="p-12 my-4 rounded-lg bg-white border border-gray-200 flex flex-col">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center mx-4 mt-4">
                <p className="font-bold">Horarios bloqueados</p>
                <Link
                  to={`/health-team/${nurseId}/block-schedule/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button color="primary">Editar</Button>
                </Link>
              </div>
              <hr />
              <Tabs
                elements={TABS}
                onSelectTab={(value: string) => setCurrentTab(value as PossibleTab)}
              >
                <div className="px-4">
                  {currentTab === "Por día" && <DisplayBlockedTimeBlock timeblocks={blockedTimeBlocks} />}
                  {currentTab === "Por item" && <DisplayBlockedItem blockedItems={blockedItems} />}
                </div>
              </Tabs>
            </div>
          </div>
          <ActionReceiverComponent
            nurseData={nurseData}
            loading={isLoading}
            setError={setError}
          />
        </>
      )}
    </>
  );
}

export default NurseProfile;

import React, { useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CancelIcon from "@material-ui/icons/Cancel";

import { medicalActionAPI, nurseAPI, receiverAPI } from "~/utils/api/v2";
import { Locale } from "~/utils/interfaces/Locale";
import { Nurse } from "~/utils/interfaces/Nurse";

type Props = {
  nurseData: Nurse;
  setError: (err: any) => void;
  loading: boolean;
};

const ActionReceiverComponent = (props: Props) => {
  const [receivers, setReceivers] = useState<any[]>([]);
  const [medicalActions, setMedicalActions] = useState<any[]>([]);
  const [actionReceivers, setActionReceivers] = useState<any[]>([]);
  const [currentReceiver, setCurrentReceiver] = useState<any>({});
  const [currentMedicalAction, setCurrentMedicalAction] = useState<any>({});
  const [currentMedicalActions, setCurrentMedicalActions] = useState<any[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const reqReceivers = await receiverAPI.list({
          with_labs: true,
          country: props.nurseData?.country,
        });
        setReceivers(reqReceivers.data);
        const reqMedicalActions = await medicalActionAPI.list(props.nurseData?.country as Locale);
        setMedicalActions(reqMedicalActions.data);
        const reqActionReceivers = await nurseAPI.listActionReceivers(props.nurseData.id);
        setActionReceivers(reqActionReceivers.data);
      } catch (err) {
        console.log(err);
        props.setError(err);
      }
    };
    if (props.nurseData) {
      fetch();
    }
  }, [props.nurseData]);

  useEffect(() => {
    const addActionReceiver = async () => {
      if (currentMedicalAction.id && currentReceiver.id) {
        const data = {
          nurseId: props.nurseData.id,
          medicalActionId: currentMedicalAction.id,
          receiverId: currentReceiver.id,
        };
        const res = await nurseAPI.addActionReceiver(data);
        setActionReceivers(res.data);
        setCurrentMedicalAction({});
      }
    };
    addActionReceiver();
  }, [currentMedicalAction.id, currentReceiver.id]);

  useEffect(() => {
    if (currentReceiver && actionReceivers.length > 0) {
      setCurrentMedicalActions(
        actionReceivers
          .filter((actionReceiver) => actionReceiver.receiver.id === currentReceiver.id)
          .map((actionReceiver) => actionReceiver.medical_action),
      );
    }
  }, [currentReceiver, actionReceivers]);

  return (
    <div className="border border-gray-300 p-4 rounded-md">
      <div className="flex flex-col">
        <h2 className="font-bold text-lg">Receptor - Acción Médica</h2>
        <hr />
        <div className="flex flex-col w-1/2">
          <span className="font-bold">Receptor Actual</span>
          <Select
            value={currentReceiver.id || ""}
            onChange={(e) => {
              const receiver = receivers.find((receiver) => receiver.id === e.target.value);
              if (receiver) {
                setCurrentReceiver(receiver);
              }
            }}
          >
            {receivers.map((receiver) => (
              <MenuItem
                key={receiver.id}
                value={receiver.id}
              >
                {receiver.name || ""}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex flex-col w-1/2">
          <span className="font-bold">Acciones Médicas Disponibles</span>
          <Select
            value={currentMedicalAction?.id || ""}
            onChange={(e) => {
              const medicalAction = medicalActions.find((medicalAction) => medicalAction.id === e.target.value);
              if (medicalAction) {
                setCurrentMedicalAction(medicalAction);
              }
            }}
          >
            {medicalActions
              .filter(
                (medicalAction) =>
                  !currentMedicalActions.map((medicalAction) => medicalAction.id).includes(medicalAction.id),
              )
              .map((medicalAction) => (
                <MenuItem
                  key={medicalAction.id}
                  value={medicalAction.id}
                >
                  {medicalAction.name || ""}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div className="flex flex-col">
          <span className="font-bold">Acciones Médicas Asignadas</span>
          <div className="flex flex-row w-1/2">
            {currentMedicalActions &&
              currentMedicalActions?.map((medicalAction) => (
                <div
                  className={`p-2 m-1 bg-${props.loading ? "gray-500" : "gray-200"} rounded-md flex items-center`}
                  key={medicalAction.id}
                >
                  {medicalAction.name}
                  <CancelIcon
                    className="ml-2 cursor-pointer"
                    fontSize="small"
                    onClick={async () => {
                      const data = {
                        nurseId: props.nurseData.id,
                        medicalActionId: medicalAction.id,
                        receiverId: currentReceiver.id,
                      };
                      const res = await nurseAPI.deleteActionReceiver(data);
                      setActionReceivers(res.data);
                      setCurrentMedicalAction({});
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionReceiverComponent;

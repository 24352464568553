import React, { useEffect, useState } from "react";

import { Button, ButtonGroup, FormControlLabel } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { useQuery } from "@tanstack/react-query";
import { Link, navigate } from "gatsby";
import Swal from "sweetalert2";

import nurseService, { fetchNurseSpecialties } from "~/utils/api/v1/nurseService";
import { COUNTRIES } from "~/utils/data/constants";
import type { Laboratory } from "~/utils/interfaces/Laboratory";
import type { Nurse, Specialty } from "~/utils/interfaces/Nurse";

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

type NurseDataProps = {
  nurseData: Nurse;
  nurseLabs: Laboratory[];
  setNurseData: (nurseData: Nurse) => void;
  handleActivate: () => void;
  setError: (error: any) => void;
};

function NurseData({
  nurseData,
  nurseLabs,
  setNurseData,
  handleActivate: handleActivateProps,
  setError,
}: NurseDataProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [nurseInitialData, setNurseInitialData] = useState<Nurse>();
  const [validPhotoUploaded, setValidPhotoUploaded] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("Chile");
  const [specialtyOptions, setSpecialtyOptions] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const { data: nurseSpecialtiesData } = useQuery({
    queryKey: ["nurseSpecialties"],
    queryFn: async () => {
      const res = await fetchNurseSpecialties();
      return res.data;
    },
  });

  const updateNurse = async (): Promise<void> => {
    setLoading(true);
    try {
      if (!nurseData) return;
      const data = {
        fingerprint_available: nurseData.fingerprint_available,
        specialties: nurseData.specialties.map((specialty) => specialty.name),
        fake: nurseData.fake,
      };
      const req = await nurseService.updateNurse(nurseData.id, data);
      const nurseInfo = req.data;
      setNurseData(nurseInfo);
    } catch (err) {
      setError(err);
    } finally {
      setEditable(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nurseSpecialtiesData) {
      const specialties = nurseSpecialtiesData.map((speciality: Specialty) => speciality.name);
      setSpecialtyOptions(specialties);
    }
    setNurseInitialData(nurseData);
  }, [nurseSpecialtiesData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // if file > 15MB, reject
      if (file.size > 15_000_000) {
        Swal.fire({
          icon: "error",
          title: "El archivo no debe pesar mas de 15MB",
        });
        setValidPhotoUploaded(false);
        return false;
      }
      // add file to files state
      setValidPhotoUploaded(true);
      setFiles((prevFiles) => {
        return [...prevFiles, file];
      });
    }
  };

  const renderSelectedOptions = (selected) => {
    return (
      <div className="flex flex-wrap">
        {selected.map((value) => (
          <Chip
            variant="outlined"
            color="primary"
            key={value}
            label={value}
          />
        ))}
      </div>
    );
  };

  const uploadProfilePic = async (): Promise<void> => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    const uploaded = await nurseService.uploadProfilePicture(nurseData.id, formData);
    if (uploaded) {
      Swal.fire({
        icon: "success",
        title: "Foto de perfil cambiada",
      });
      setLoading(false);
      setEditable(false);
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "La foto no se pudo subir",
      });
    }
  };

  const handleActivate = async () => {
    const check = await Swal.fire({
      title: "¿Estás seguro?",
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) {
      return;
    }
    handleActivateProps();
  };

  const handleCancel = () => {
    if (!nurseInitialData) return;
    setNurseData(nurseInitialData);
    setEditable(false);
  };

  const countrySelector = (): JSX.Element => {
    return (
      <FormControl fullWidth>
        <Select
          fullWidth
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          {COUNTRIES.map((name) => (
            <MenuItem
              value={name}
              key={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>País</FormHelperText>
      </FormControl>
    );
  };

  const specialtySelector = (): JSX.Element => {
    return (
      <FormControl fullWidth>
        <Select
          multiple
          fullWidth
          value={nurseData.specialties.map((specialty) => specialty.name)}
          onChange={(event) => {
            const selectedNames = event.target.value as string[];
            const selectedSpecialties = nurseSpecialtiesData?.filter((specialty) =>
              selectedNames.includes(specialty.name),
            );
            setNurseData((prev) => ({
              ...prev,
              specialties: selectedSpecialties,
            }));
          }}
          input={<Input id="select-multiple-chip" />}
          MenuProps={locationSelectProps}
          renderValue={renderSelectedOptions}
        >
          {specialtyOptions?.map((specialty) => (
            <MenuItem
              key={specialty}
              value={specialty}
            >
              {specialty}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Especialidades</FormHelperText>
      </FormControl>
    );
  };

  return (
    <div className="p-12 my-4 rounded-lg bg-white border border-gray-200 flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mx-4 mt-4">
          <p className="font-bold">Datos personales</p>
          {editable ? (
            <ButtonGroup variant="text">
              <Button
                color="secondary"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={updateNurse}
              >
                Actualizar
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="text">
              {nurseData.active ? (
                <Button
                  color="secondary"
                  onClick={handleActivate}
                >
                  Desactivar
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={handleActivate}
                >
                  Activar
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => {
                  setEditable(true);
                }}
              >
                Editar
              </Button>
              <Button
                color="primary"
                onClick={async () =>
                  navigate(`/health-team/${nurseData.id}/exams/`, {
                    state: nurseData,
                  })
                }
              >
                Detalle exámenes
              </Button>
            </ButtonGroup>
          )}
        </div>
        <hr />
        {loading ? (
          <div className="flex items-center justify-center p-12">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex p-8 justify-between">
            <div className="flex flex-col justify-center">
              {editable ? (
                <div className="flex flex-col">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleChange}
                  />
                  <Button
                    style={{ marginTop: 10 }}
                    color="primary"
                    variant="contained"
                    disabled={!validPhotoUploaded}
                    onClick={uploadProfilePic}
                  >
                    Subir Foto de Perfil
                  </Button>
                </div>
              ) : (
                <img
                  className="object-contain rounded-full w-40 h-40"
                  src={nurseData.profile_picture}
                  alt="HT Profile Picture"
                />
              )}
            </div>
            <div className="grid grid-cols-[6rem_1fr] grid-rows-4">
              <p className="font-bold">Nombre: </p>
              {nurseData.names} {nurseData.last_names}
              <p className="font-bold">Email: </p>
              {nurseData.email}
              {nurseData.rut !== "" && <p className="font-bold">Rut: </p>}
              {nurseData.rut}
              <p className="font-bold">Teléfono: </p>
              {nurseData.phone}
            </div>
            <div className="flex flex-col items-center">
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editable}
                    name="¿Tiene huellero?"
                    color="primary"
                    checked={nurseData.fingerprint_available}
                    onChange={(e) => {
                      setNurseData({
                        ...nurseData,
                        fingerprint_available: e.target.checked,
                      });
                    }}
                  />
                }
                label="¿Tiene huellero?"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editable}
                    name="Cuenta Falsa"
                    color="primary"
                    checked={nurseData.fake}
                    onChange={(e) => {
                      setNurseData({
                        ...nurseData,
                        fake: e.target.checked,
                      });
                    }}
                  />
                }
                label="Cuenta Falsa"
              />
              <p className="font-bold">Sectores:</p>
              <Link to="/sectors">Ir a sectores</Link>
              <Button></Button>
              {editable && (
                <div className="flex flex-wrap justify-center items-center py-4 overflow-auto max-w-sm">
                  {specialtySelector()}
                  {countrySelector()}
                </div>
              )}
              {!editable && (
                <>
                  <p className="font-bold">Especialidades:</p>
                  {nurseData.specialties?.length == 0 && "No tiene especialidades"}
                  {nurseData.specialties?.length > 0 && (
                    <div className="flex overflow-auto max-w-sm">
                      {nurseData.specialties.map(({ name, id }) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          key={id}
                          label={name}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              <div className="p-2">
                <p className="font-bold">Laboratorios:</p>
                {nurseLabs.map((lab, index) => (
                  <p key={`laboratory-${index}`}>{lab.display_name}</p>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NurseData;
